import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import styled from 'styled-components';


const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    margin: 0;
    box-size: border-box; 
    overflow: hidden;
    background-color: #fffff0;
    
`;

const Container = styled.div`
    padding: 10px;
    height: calc(100vh - 80px - 20px);
    position: relative;
`;

const Header = styled.div`
    width:100%;
    height: 80px;
    background-color: #0a0a0a;
    display: grid;
    grid-template-columns: 11% 89%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    
    p {
        color: #fff;
        margin: 15px 0 10px;
    }
    
    @media screen and (max-width: 767px) {
        grid-template-columns: 33% 66%;
    }
`;

const Logo = styled.span`
    font-family: 'Staatliches', cursive;
    font-size: 55px;
    color: #fff;
    margin: 0 20px;
`;

const Description = styled.span`
    font-family: 'Orbitron', sans-serif;
    color: #fff;
`;


class Layout extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true
        }
    }

    async componentDidMount() {
        this.setState({
            loading: false
        });
    }

    render() {
        const {loading} = this.state;
        if (loading) return <p>Loading...</p>
        return <Wrapper>
            <Header>
                <div>
                    <Logo>GCG</Logo>
                </div>
                <div>
                    <p>Support Center</p>
                    <Description>Business Solutions</Description>
                </div>
            </Header>
            <Container>
                {this.props.children}
            </Container>
        </Wrapper>
    }
}

export default withRouter(Layout);