import {
    LoadingOutlined
} from '@ant-design/icons';
import React, {Component} from "react";

class Spinner extends Component {

    render() {
        return <LoadingOutlined style={{
            fontSize: 36,
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%'
        }} />;
    }

}

export default Spinner;