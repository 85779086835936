import React, {Component} from 'react';
import {Redirect, withRouter} from "react-router-dom";
import styled from "styled-components";
import {Form, Input, Button, Card, Typography, Empty} from "antd";
import Spinner from "../Spinner";
import requests from "../requests";



const Centered = styled.div`
    width: 100%;
    max-width: 400px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
`;


class InitChat extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            errorMessage: null,
            room: null
        }
    }

    onFinish = async values => {
        const {affId} = this.props.match.params;
        values.affiliate = affId;
        const r = await requests.post('chat/init_agent_room', values);
        if (r.status === 200) {
            sessionStorage.setItem('name', values.name);
            this.setState({
                room: r.data.room
            });
            sessionStorage.setItem('aff', affId);
        }
    }

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    }

    async componentDidMount() {
        const {affId} = this.props.match.params;
        const r = await requests.get(`chat/check_agent?affiliate=${affId}`);
        if (r.status !== 200) {
            this.setState({
                errorMessage: r.error
            });
        }
        this.setState({
            loading: false
        });
    }

    render() {
        const {loading, errorMessage, room} = this.state;
        if (loading) return <Spinner/>;
        if (room) return <Redirect to={`/room/${room}`}/>;
        return <Centered>
            {errorMessage ? (<Empty description={errorMessage}/>) : (
                <Card>
                    <Form
                        layout={'vertical'}
                        name="basic"
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            extra={'Enter your name in order to start the chat'}
                            rules={[{ required: true, message: 'Please input your name!' }]}
                        >
                            <Input placeholder={'Enter your name'} />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" block shape={'round'}>
                                Start
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            )}
        </Centered>
    }
}

export default withRouter(InitChat);