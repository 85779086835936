import axios from 'axios';

const HOST = 'https://crex.gcg.technology/';

class requests {


    static get = (path, params= {}, headers={}) => {
        return axios({
            method: 'get',
            url: `${HOST}${path}`,
            headers: headers,
            data: params
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            return {
                error: error.response.data.error,
                data: error.response.data,
                status: error.response.status
            };
        });
    }

    static post = (path, params = {}, headers={}) => {
        return axios({
            method: 'post',
            url: `${HOST}${path}`,
            headers: headers,
            data: params
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            return {
                error: error.response.data.error,
                data: error.response.data,
                status: error.response.status
            };
        });
    }
}

export default requests;