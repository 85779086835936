import React, {Component} from 'react';
import {Redirect, withRouter} from "react-router-dom";
import styled from "styled-components";
import {Form, Input, Button, Card, message} from "antd";
import requests from "../requests";



const Centered = styled.div`
    width: 100%;
    max-width: 400px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
`;


class Join extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            buttonLoad: false
        }
    }

    onFinish = async values => {
        this.setState({
            buttonLoad: true
        });
        const {join} = this.props;
        const {id} = this.props.match.params;
        values.room_id = id;
        const r = await requests.post('chat/join_agent', values);
        if (r.status === 200) {
            join(values.name);
        } else {
            message.error(r.error);
            this.setState({
                buttonLoad: false
            });
        }
    }

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    }

    render() {
        const {buttonLoad} = this.state;
        return <Centered>
            <Card>
                <Form
                    layout={'vertical'}
                    name="basic"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        extra={'Enter your name in order to join the chat'}
                        rules={[{ required: true, message: 'Please input your name!' }]}
                    >
                        <Input placeholder={'Enter your name'} />
                    </Form.Item>

                    <Form.Item>
                        <Button loading={buttonLoad} type="primary" htmlType="submit" block shape={'round'}>
                            Join
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Centered>
    }
}

export default withRouter(Join);