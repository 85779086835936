import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import styled from "styled-components";
import {MessageList} from "react-chat-elements";
import {Typography, Input, Button, message, Upload} from 'antd';
import Spinner from "../Spinner";
import requests from "../requests";
import * as firebase from "firebase";
import {
    UserOutlined,
    PaperClipOutlined,
    CloseOutlined
} from '@ant-design/icons';
import Join from "./Join";
import {beforeUpload, getBase64} from "../Funcs";
import Viewer from 'react-viewer';


const Wrap = styled.div`
    display: grid;
    grid-template-columns: 80% 19%;
    grid-gap: 10px;
    
    @media screen and (max-width: 767px) {
        grid-template-columns: 100%;
    }
`;

const Box = styled.div`
    padding: 10px;
    background-color: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    width: 100%;
    position: relative;
    
    &.hide-mob {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
`;

const InputWrapper = styled.div`
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
`;

const OptButtons = styled.div`
    button {
        margin: 5px 0;
    }
`;

const {Title, Paragraph, Text} = Typography;

class Room extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            messages: [],
            participants: [],
            buttonLoad: false,
            name: sessionStorage.getItem('name'),
            messageInput: null,
            file: null,
            viewer: false,
            viewerIndex: 0
        }
        this.sound = new Audio('https://crex.gcg.technology/static/mp3/chat_alart.mp3');
    }

    async componentDidMount() {
        const {id} = this.props.match.params;
        const r = await requests.get(`chat/get_ref?room_id=${id}`);
        if (r.status === 200) {
            let req = firebase.database().ref('agentrooms').child(r.data.ref);
            await req.on("value", (snapshot) => {
                const data = snapshot.val();
                this.setState({
                    messages: data.messages,
                    participants: data.participants,
                    loading: false
                });
                this.sound.play();
            });
        }
    }

    send = async e => {
        e.preventDefault();
        this.setState({
            buttonLoad: true
        });
        const {messageInput, file} = this.state;
        const {id} = this.props.match.params;
        const r = await requests.post('chat/send_agent_message', {
            name: sessionStorage.getItem('name'),
            room_id: id,
            text: messageInput,
            file: file
        });
        if (r.status === 200) {
            this.setState({
                messageInput: null,
                buttonLoad: false,
                file: null
            });
        } else {
            this.setState({
                buttonLoad: false
            });
            message.error(r.error);
        }
    }

    join = name => {
        sessionStorage.setItem('name', name);
        this.setState({
            name: name
        });
    }

    setDocument = async (file) => {
        if(beforeUpload(file)){
            const base64 = await getBase64(file);
            await this.setState({
                file: base64
            });
        }
    }

    upload = async () => {
        const {file} = this.state;
        let r = await requests.post('/utils/upload', {
            file: file
        });
        if (r.status === 200) {
            this.setState({
                file: r.data.url
            });
            console.log(r.data.url);
        }
    }

    getFiles = () => {
        const {messages} = this.state;
        const images = [];
        messages.map((v) => {
           if (v.file) images.push({src: v.file, alt: 'File'});
           return null;
        });
        return images;
    }

    onClick = (e) => {
        switch (e.type) {
            case 'photo':
                const files = this.getFiles();
                const list = [];
                files.map((v) => {
                    list.push(v.src)
                    return null;
                })
                this.setState({
                    viewer: true,
                    viewerIndex: list.indexOf(e.data.uri)
                });
                break;
            case 'file':
                window.open(e.data.uri, '_blank')
                break;
            default:
                return null;
        }
    }

    leave = () => {
        const {name} = this.state;
        const {id} = this.props.match.params;
        sessionStorage.removeItem('name');
        this.setState({
            name: null
        });
        requests.post('chat/leave_agent', {
            name: name,
            room_id: id
        });
    }

    render() {
        const {loading, participants, messages, buttonLoad, name, messageInput, file, viewer, viewerIndex} = this.state;
        if (loading) return <Spinner/>;
        const uploadProps = {
            beforeUpload: (file) => this.setDocument(file),
            showUploadList: false
        }
        const {id} = this.props.match.params;
        const dataSource = [];
        messages.map((v, i) => {
            dataSource.push({
                title: v.name,
                text: v.text,
                type: v.type,
                position: v.name === name ? 'right' : 'left',
                date: new Date(v.time),
                data: v.file  ? {
                    uri: v.file,

                } : null,
            })
            return null;
        })
        return name ? (
            <Wrap>
                <Box>
                    <MessageList
                        onClick={this.onClick}
                        className='message-list'
                        lockable={false}
                        toBottomHeight={'100%'}
                        dataSource={dataSource}
                    />
                    <InputWrapper>
                        <form onSubmit={this.send}>
                            <div style={{
                                textAlign: 'right',
                                padding: '10px'
                            }}>{file && [
                                <PaperClipOutlined />,
                                ' File Attached',
                                <Button onClick={() => this.setState({
                                    file: null
                                })} type={'link'} icon={<CloseOutlined />}/>
                                ]}</div>
                            <Input
                                placeholder="Type here..."
                                value={messageInput}
                                suffix={[
                                    <Upload
                                        customRequest={() => this.upload()}
                                        {...uploadProps}
                                    >
                                        <Button type={'link'} icon={<PaperClipOutlined />} />
                                    </Upload>,
                                    <Button loading={buttonLoad} type={'primary'} shape={'round'} htmlType={'submit'}>Send</Button>
                                ]}
                                onChange={(e) => this.setState({
                                    messageInput: e.target.value
                                })}
                            />
                        </form>
                    </InputWrapper>
                </Box>
                <Box className={'hide-mob'}>
                    <Paragraph copyable={{ text: `https://support.gcg.technology/room/${id}` }}>Copy invite link</Paragraph>
                    <OptButtons>
                        {sessionStorage.getItem('aff') && <Button block shape={'round'} type={'primary'} onClick={() => window.location.href = `/init/${sessionStorage.getItem('aff')}`}>Open New Room</Button>}
                        <Button block shape={'round'} type={'danger'} onClick={this.leave}>Leave Room</Button>
                    </OptButtons>
                    <Title level={4}>Participants:</Title>
                    {participants.map((v, i) => {
                        return <Paragraph key={i}><UserOutlined /> {v}</Paragraph>
                    })}
                </Box>
                <Viewer
                    visible={viewer}
                    onClose={() => this.setState({viewer: false}) }
                    images={this.getFiles()}
                    activeIndex={viewerIndex}
                />
            </Wrap>
        ) : (
            <Join join={this.join}/>
        )
    }
}

export default withRouter(Room);