import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Layout from "./Layout";
import InitChat from "./chat/InitChat";
import Room from "./chat/Room";
import 'antd/dist/antd.css';
import 'react-chat-elements/dist/main.css';
import config from './firebase';
import * as firebase from 'firebase/app';
import './App.css';
firebase.initializeApp(config);


class App extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true
        }
    }

    async componentDidMount() {
        this.setState({
            loading: false
        });
    }

    render() {
        const {loading} = this.state;
        if (loading) return <p>Loading...</p>
        return <Router basename={'/'}>
            <Switch>
                <Layout>
                    <Route exact={true} path="/init/:affId" render={() => <InitChat/>}/>
                    <Route exact={true} path="/room/:id" render={() => <Room/>}/>
                </Layout>
            </Switch>
        </Router>
    }
}

export default App;